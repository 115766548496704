@tailwind base;
@tailwind components;
@tailwind utilities;
.section-editor-container {
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  height: 100vh;
  overflow-y: auto;
}

.input-field {
  width: 100%;
  margin-bottom: 15px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.date-picker {
  width: 100%;
  margin-bottom: 15px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #0056b3;
}

.sidebar {
  width: 250px;
  height: 100vh;
  background-color: #333;
  color: #fff;
  padding-top: 20px;
  position: relative;
  overflow-y: auto;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar li {
  margin-bottom: 10px;
}

.sidebar a {
  text-decoration: none;
  color: #fff;
  font-size: 18px;
}

.sidebar a:hover {
  color: #007bff;
}

/* Стили для контента, который находится справа от боковой панели */
.content {
  margin-left: 250px;
  padding: 20px;
}

/* Опциональные стили для закрепления боковой панели */
.fixed-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1000;
}

/* Стили для элемента select */
.select-input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* Стили для кнопки Add */
.add-button {
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  margin-top: 10px;
  font-size: 16px;
}

.add-button:hover {
  background-color: #45a049;
}

/* Стили для списка отображаемых опций */
.options-list {
  list-style-type: none;
  padding: 0;
}

.option-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 16px;
}

/* Стили для кнопки Remove */
.remove-button {
  background-color: #ff4646;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 8px 25px;
  margin-left: 10px;
  cursor: pointer;
}

.remove-button:hover {
  background-color: #ff0000;
}

.gradient-box {
  background: linear-gradient(to right, #ff9966, #ff5e62);
  padding: 5px;
}
.gradient-box2 {
  background: linear-gradient(to right, #0099ff, #33cc33);
  padding: 5px;
}

.logout-button {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #007bff;
  color: white;
  border: none;
  padding: 15px 30px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.logout-button:hover {
  background-color: #0056b3;
}
.centered-text {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}
